import React, { useEffect, useState, useCallback, useRef } from "react";
import styled from "styled-components";
import { Helmet } from 'react-helmet';
import { company_code, base_url, api_website, api_admin } from "./config";
import crud_service from "./ApiService/CrudService";
import { useDispatch } from "react-redux";
import { logoutUser } from "./Redux/slices/user";
import {
  homeGetAllStart,
  homeGetAllSuccess,
  homeGetAllFail,
} from "./Redux/slices/homeSlice";
import {
  templateorderGetAllStart,
  templateorderGetAllSuccess,
  templateorderGetAllFail,
} from "./Redux/slices/templateOrderArraySlice";
import {
  productcollectionpageGetAllStart,
  productcollectionpageGetAllSuccess,
  productcollectionpageGetAllFail,
} from "./Redux/slices/productCollectionPage";
import {
  pricedecimalGetAllStart,
  pricedecimalGetAllSuccess,
  pricedecimalGetAllFail,
} from "./Redux/slices/priceDecimalsSlice";
import {
  specificationGetAllStart,
  specificationGetAllSuccess,
  specificationGetAllFail,
} from "./Redux/slices/specificationSlice";
import {
  specificationdetailsGetAllStart,
  specificationdetailsGetAllSuccess,
  specificationdetailsGetAllFail,
} from "./Redux/slices/specificationdetailsSlice";
import {
  aboutusGetAllStart,
  aboutusGetAllSuccess,
  aboutusGetAllFail,
} from "./Redux/slices/aboutus";
import {
  privacypolicyGetAllStart,
  privacypolicyGetAllSuccess,
  privacypolicyGetAllFail,
} from "./Redux/slices/privacypolicy";
import {
  refundpolicyGetAllStart,
  refundpolicyGetAllSuccess,
  refundpolicyGetAllFail,
} from "./Redux/slices/refundpolicy";
import {
  deliverypolicyGetAllStart,
  deliverypolicyGetAllSuccess,
  deliverypolicyGetAllFail,
} from "./Redux/slices/deliverypolicy";
import {
  returnpolicyGetAllStart,
  returnpolicyGetAllSuccess,
  returnpolicyGetAllFail,
} from "./Redux/slices/returnpolicy";
import {
  cancellationpolicyGetAllStart,
  cancellationpolicyGetAllSuccess,
  cancellationpolicyGetAllFail,
} from "./Redux/slices/cancellationpolicy";
import {
  homeCustomGetAllStart,
  homeCustomGetAllSuccess,
  homeCustomGetAllFail,
} from "./Redux/slices/homeCustomSlice";
import {
  socialMediaGetAllStart,
  socialMediaGetAllSuccess,
  socialMediaGetAllFail,
} from "./Redux/slices/socialMedia";
import {
  companyGetAllStart,
  companyGetAllSuccess,
  companyGetAllFail,
} from "./Redux/slices/company";
import {
  bannerGetAllStart,
  bannerGetAllSuccess,
  bannerGetAllFail,
} from "./Redux/slices/banner";
import {
  categoryGetAllStart,
  categoryGetAllSuccess,
  categoryGetAllFail,
} from "./Redux/slices/category";
import {
  CetgoryMenuGetAllStart,
  CetgoryMenuGetAllSuccess,
  CetgoryMenuGetAllFail,
} from "./Redux/slices/categoryMenu";
import {
  productGetAllStart,
  productGetAllSuccess,
  productGetAllFail,
} from "./Redux/slices/product";
import {
  allProductGetAllStart,
  allProductGetAllSuccess,
  allProductGetAllFail,
} from "./Redux/slices/allproduct";
import {
  allConversionapiGetAllStart,
  allConversionapiGetAllSuccess,
  allConversionapiGetAllFail,
} from "./Redux/slices/conversionapi";
import {
  taxGetAllStart,
  taxGetAllSuccess,
  taxGetAllFail,
} from "./Redux/slices/tax";
import {
  getSigleProductStart,
  getSigleProductSuccess,
  getSigleProductFail,
} from "./Redux/slices/singleProduct";
import {
  profileGetAllStart,
  profileGetAllSuccess,
  profileGetAllFail,
} from "./Redux/slices/profile";
import {
  contactusGetAllStart,
  contactusGetAllSuccess,
  contactusGetAllFail,
} from "./Redux/slices/contactus";
import "./Assets/Css/style.css";
import FooterMain from "./Components/Pages/FooterMain";
import HeaderMain from "./Components/Pages/HeaderMain";
import {
  termsAndConditionGetAllStart,
  termsAndConditionGetAllSuccess,
  termsAndConditionGetAllFail,
} from "./Redux/slices/termsAndCondition";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Alert, Flex, Spin } from "antd";
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer';
import { commonRouter } from "./ApiService/DynamicRouter";
import { useSelector } from "react-redux";
import Blog from "./Components/Pages/Blogs/Blog";
import "./App.css";
// import { delay } from "lodash";

// const delay = ms => new Promise(
//   resolve => setTimeout(resolve, ms)
// );

const IDLE_TIMEOUT = 4 * 60 * 60 * 1000;

const App = () => {
  const crud = new crud_service();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [cardTypes1, setCardTypes1] = useState();
  const [content1, setContent1] = useState();

  const idleTimerRef = useRef(null);

  //getInitRender
  const getInitRender = async () => {
    await crud.getAll(
      dispatch,
      [homeGetAllStart, homeGetAllSuccess, homeGetAllFail],
      "section",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [aboutusGetAllStart, aboutusGetAllSuccess, aboutusGetAllFail],
      "aboutus",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        privacypolicyGetAllStart,
        privacypolicyGetAllSuccess,
        privacypolicyGetAllFail,
      ],
      "privacypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        refundpolicyGetAllStart,
        refundpolicyGetAllSuccess,
        refundpolicyGetAllFail,
      ],
      "refundpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        deliverypolicyGetAllStart,
        deliverypolicyGetAllSuccess,
        deliverypolicyGetAllFail,
      ],
      "deliverypolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        termsAndConditionGetAllStart,
        termsAndConditionGetAllSuccess,
        termsAndConditionGetAllFail,
      ],
      "terms-and-condition",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        returnpolicyGetAllStart,
        returnpolicyGetAllSuccess,
        returnpolicyGetAllFail,
      ],
      "returnpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [
        cancellationpolicyGetAllStart,
        cancellationpolicyGetAllSuccess,
        cancellationpolicyGetAllFail,
      ],
      "cancellationpolicy",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [contactusGetAllStart, contactusGetAllSuccess, contactusGetAllFail],
      "contact",
      "",
      (err, res) => {}
    );

    // await crud.getAllTax(
    //   dispatch,
    //   [taxGetAllStart, taxGetAllSuccess, taxGetAllFail],
    //   "tax",
    //   "",
    //   (err, res) => {}
    // );
    await crud.getAll(
      dispatch,
      [profileGetAllStart, profileGetAllSuccess, profileGetAllFail],
      "profile",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [homeCustomGetAllStart, homeCustomGetAllSuccess, homeCustomGetAllFail],
      "templatecustom",
      "",
      (err, res) => {}
    );
    await crud.getCommonAll1(
      dispatch,
      [templateorderGetAllStart, templateorderGetAllSuccess, templateorderGetAllFail],
      "cms",
      { companyCode: company_code },
      (err, res) => {}
    );
    await crud.getCommonAll1(
      dispatch,
      [pricedecimalGetAllStart, pricedecimalGetAllSuccess, pricedecimalGetAllFail],
      "PriceDecimals",
      { company_code: company_code },
      (err, res) => {}
    );
    await crud.getCommonAll2(
      dispatch,
      [specificationGetAllStart, specificationGetAllSuccess, specificationGetAllFail],
      "specification",
      { company_code: company_code },
      (err, res) => {}
    );
    await crud.getCommonAll2(
      dispatch,
      [specificationdetailsGetAllStart, specificationdetailsGetAllSuccess, specificationdetailsGetAllFail],
      "specificationdetails",
      { company_code: company_code },
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [socialMediaGetAllStart, socialMediaGetAllSuccess, socialMediaGetAllFail],
      "socialmedia",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [companyGetAllStart, companyGetAllSuccess, companyGetAllFail],
      "company",
      "",
      (err, res) => {
      }
    );
    await crud.getAll(
      dispatch,
      [bannerGetAllStart, bannerGetAllSuccess, bannerGetAllFail],
      "banner",
      "",
      (err, res) => {}
    );

    await crud.getAll(
      dispatch,
      [categoryGetAllStart, categoryGetAllSuccess, categoryGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAllCategory(
      dispatch,
      [CetgoryMenuGetAllStart, CetgoryMenuGetAllSuccess, CetgoryMenuGetAllFail],
      "category",
      "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [productGetAllStart, productGetAllSuccess, productGetAllFail],
      "products",
      {
        company_code: company_code,
        page: 1,
        perpage: 10,
      },
      // "",
      (err, res) => {}
    );
    await crud.getAll(
      dispatch,
      [allProductGetAllStart, allProductGetAllSuccess, allProductGetAllFail],
      "allproducts",
      {
        company_code: company_code,
        page: 1,
        perpage: 2000000000,
      },
      // "",
      (err, res) => {}
    );
    await crud.getCommonAll1(
      dispatch,
      [allConversionapiGetAllStart, allConversionapiGetAllSuccess, allConversionapiGetAllFail],
      "conversionapi_configuration",
      { company_code: company_code },
      // "",
      (err, res) => {}
    );

    // await delay(1000);
    setIsLoading(false);
  };

  const getInitRender1 = async () => {   
    await crud.getCommonAll1(
      dispatch,
      [productcollectionpageGetAllStart, productcollectionpageGetAllSuccess, productcollectionpageGetAllFail],
      "product-collection",
      { company_code: company_code },
      (err, res) => {}
    );    
    // setIsLoading(false);
  };

  var cardTypes = [];
  var isToken = "";

  const getAllData = async () => {
    await crud.getCommonAll("cms",{ companyCode: company_code },(err, res) => {
      // console.log("res===????",res?.data?.data[res?.data?.data?.length - 1]?.content)
      const content = res?.data?.company?.[0]?.content;
      // const content = res?.data?.data[res?.data?.data?.length - 1]?.content;
      // setContent1(content);
      // const cardTypes = [];

      if (content) {
        for (const key in content) {
          if (content[key].cardType) {
            cardTypes.push(content[key].cardType);
            setCardTypes1(cardTypes);
          }
        }
      }   
      const removeUniqueIdentifiers = (obj) => {
        const newObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const newKey = key.split('-')[0];
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    };
    
    const newContent = removeUniqueIdentifiers(content);
    setContent1(newContent);
      localStorage.setItem("cardTypes", JSON.stringify(cardTypes));
      localStorage.setItem("content", JSON.stringify(newContent));
      // console.log("cardTypes", cardTypes);   
    });
  };

  // console.log("cardTypes1", cardTypes1?.[0]);
  const Wrapper = ({ children }) => {
    const location = useLocation();
    React.useLayoutEffect(() => {
      document.documentElement.scrollTo(500, 0);
    }, [location?.pathname]);
    return children;
  };
  //init
  useEffect(() => {
    getInitRender();
    getInitRender1();
    getAllData();
    isToken = localStorage.getItem("token") || "";
    // let login = localStorage.getItem("login") || false;
    // let userData = localStorage.getItem("user") || null;
    // console.log("isToken123", isToken);
  }, []);

   const logout = () => {
      //data of the user will be deleted from local storage after logout and also set null value to userSlice
      dispatch(logoutUser(null));
      // dispatch(emptyRemoveAddress(null));
      // dispatch(emptyUpdateAddress(null));
      // dispatch(emptyUserAddress(null));
      localStorage.removeItem("lastActiveTime");
      localStorage.setItem("user", "");
      localStorage.setItem("token", "");
      localStorage.setItem("login", false);
      localStorage.clear();
    };

    const onIdle = async() => {
      logout();
    };

    const onAction = () => {
      localStorage.setItem("lastActiveTime", Date.now());
    };

    useEffect(() => {
      if(isToken == "" || isToken == null || isToken == undefined){
        logout();
      }
    }, [isToken]);

    useEffect(() => {
      const lastActiveTime = localStorage.getItem("lastActiveTime");
      if (lastActiveTime) {
        const currentTime = Date.now();
        if (currentTime - lastActiveTime >= IDLE_TIMEOUT) {
          logout();
        }
      }
    }, []);

  const homecustom = useSelector(
    (state) => state?.homeCustomSlice?.homecustom?.data[0]
  );

  // console.log("homecustom from app", homecustom);
  const company = useSelector((state) => state?.company?.company?.data[0]);
  // console.log("company--->", company);
  const socialmedia = useSelector(
    (state) => state?.socialMedia?.socialmedia?.data
  );
  // console.log("socialmedia--->", socialmedia);

  const isDecimal = useSelector((state) => state?.priceDecimalsSlice?.pricedecimal?.[0]);
  // console.log("isDecimal==>", isDecimal);

  localStorage.setItem("isDecimal", JSON.stringify(isDecimal));

  function MyPage() {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": `${company?.company_name}`,
      "image": `${process.env.REACT_APP_BASE + company?.company_logo}`,
      "description": "This is an awesome product.",
      "logo": `${process.env.REACT_APP_BASE + company?.company_logo}`,
      "telephone": `+${company?.contact_number}`,
      "email": `${company?.email_id}`,
      "address": {
      "@type": "PostalAddress",
      "streetAddress": `${company?.company_addresss}`,
      "addressLocality": `${company?.city}`,
      "addressRegion": `${company?.state}`,
      "addressCountry": "India",
      "postalCode": `${company?.pin_code}`
      },
      "url": `${company?.website}`,
      "sameAs": company?.social_media?.map((e) => {
          return e?.link;
        })      
    };

    return (
      <div>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </Helmet>
      </div>
    );
  }
  

  return (
    <>
      {isLoading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
            // color: "#f45b7a",
          }}
        >
          {/* <Router>
          <Wrapper>
            <HeaderMain
              header_type={homecustom?.theme ? homecustom?.theme : ""}
              socialmedia={socialmedia}
              home_custom={homecustom}
              company={company}
            />
            <Routes>
              {commonRouter?.map((e, i) => (
                <>
                  <Route key={i} exact path={e?.path} element={e?.element} />
                </>
              ))}
            </Routes>

            <FooterMain
              footer_type={homecustom?.theme ? homecustom?.theme : ""}
              home_custom={homecustom}
              company={company}
              socialmedia={socialmedia}
            />
          </Wrapper>
        </Router> */}
        </Spin>
      ) : (
        <Router>
          <IdleTimerProvider ref={idleTimerRef} timeout={IDLE_TIMEOUT} onIdle={onIdle} onAction={onAction} debounce={500}>
            <MyPage />
            <Wrapper>
            <HeaderWrapper>
              <HeaderMain
                // header_type={"HeaderWithWhiteTransparentBackground"}
                header_type={cardTypes1?.[0]}
                socialmedia={socialmedia}
                home_custom={homecustom}
                company={company}
                content_all={content1}
              />
              </HeaderWrapper>
              <div className="main-content" style={{ marginTop: "77px" }}>
              <Routes>
                {commonRouter?.map((e, i) => (
                  <>
                    <Route key={i} exact path={e?.path} element={e?.element} />
                  </>
                ))}
              </Routes>
              </div>
              <FooterMain
                // footer_type={"Footer12"}
                footer_type={cardTypes1?.[cardTypes1.length - 1]}
                company={company}
                socialmedia={socialmedia}
                home_custom={homecustom}
                content_all={content1}
              />
            </Wrapper>
          </IdleTimerProvider>
        </Router>
      )}
    </>
  );
};

export default App;

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
`;
