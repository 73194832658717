import React, { Suspense, lazy } from 'react';

const Home = lazy(() => import('../Components/Pages/Home'));
const Login = lazy(() => import('../Ecommerce/Login'));
const Shop = lazy(() => import('../Ecommerce/Shop'));
const NewOffer = lazy(() => import('../Ecommerce/NewOffer'));
const Products = lazy(() => import('../Ecommerce/Products'));
const Cart = lazy(() => import('../Ecommerce/Cart'));
const NoPages = lazy(() => import('../Components/Pages/NoPages'));
const Checkout = lazy(() => import('../Ecommerce/Checkout'));
const MyProfile = lazy(() => import('../Ecommerce/MyProfile'));
const Register = lazy(() => import('../Ecommerce/Register'));
const BillingAddress = lazy(() => import('../Ecommerce/BillingAddress'));
const MyOrder = lazy(() => import('../Ecommerce/MyOrder'));
const AddAddress = lazy(() => import('../Ecommerce/AddAddress'));
const AboutUsMain = lazy(() => import('../Components/Pages/AboutUsMain'));
const ContactUsMain = lazy(() => import('../Components/Pages/ContactUsMain'));
const CheckoutMain = lazy(() => import('../Components/Pages/CheckoutMain'));
const ResultPage = lazy(() => import('../Components/Pages/Result'));
const OrderDetail = lazy(() => import('../Ecommerce/OrderDetail'));
const PrivacyPolicy = lazy(() => import('../Components/Pages/PrivacyPolicy/PrivacyPolicy'));
const AllCategories = lazy(() => import('../Components/Pages/AllCategories'));
const RefundPolicy = lazy(() => import('../Components/Pages/RefundPolicy/RefundPolicy'));
const DeliveryPolicy = lazy(() => import('../Components/Pages/DeliveryPolicy/DeliveryPolicy'));
const ReturnPolicy = lazy(() => import('../Components/Pages/ReturnPolicy/ReturnPolicy'));
const CancellationPolicy = lazy(() => import('../Components/Pages/CancellationPolicy/CancellationPolicy'));
const TermsAndCondition = lazy(() => import('../Components/Pages/TermsAndCondition/TermsAndCondition'));
const BlogDetail = lazy(() => import('../Components/Pages/Blogs/BlogDetail'));
const ForgotPassword = lazy(() => import('../Components/Pages/ForgotPassword'));
const VerifyOtp = lazy(() => import('../Components/Pages/VerifyOtp'));
const ChangePassword = lazy(() => import('../Components/Pages/ChangePassword'));
const WishList = lazy(() => import('../Ecommerce/WishList'));
const SitemapGenerator = lazy(() => import('../Ecommerce/SitemapGenerator'));

export const commonRouter = [
  {
    exact: true,
    index: true,
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Home />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/my-profile",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyProfile />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/delivery-address",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <BillingAddress />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/add-address",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AddAddress />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/my-order",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MyOrder />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/checkout",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CheckoutMain />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/login",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Login />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/register",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Register />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/Products/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Shop />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/new-offer",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NewOffer />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/Products",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Shop />
      </Suspense>
    ),
  },
  {
    exact: true,
    // path: "/product/:id",
    path: "/product/:productName/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Products />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/all-categories",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AllCategories />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/cart",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Cart />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/wish-list",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <WishList />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/about",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <AboutUsMain />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <PrivacyPolicy />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/refund-policy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <RefundPolicy />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/terms-and-condition",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <TermsAndCondition />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/delivery-policy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <DeliveryPolicy />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/return-policy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ReturnPolicy />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/cancellation-policy",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <CancellationPolicy />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/contact",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ContactUsMain />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "*",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <NoPages />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/success",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ResultPage />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/my-order/order/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <OrderDetail />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/blog/:id",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <BlogDetail />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/forgot-password",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ForgotPassword />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <VerifyOtp />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/change-password",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <ChangePassword />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/sitemap",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <SitemapGenerator />
      </Suspense>
    ),
  },

];
