import { createSlice } from "@reduxjs/toolkit";

const conversionapi = createSlice({
  name: "conversionapi",
  initialState: {
    conversionapi: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    allConversionapiGetAllStart: (state) => {
      state.isFetching = true;
    },
    allConversionapiGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.allproduct = action.payload;
    },
    allConversionapiGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
    allConversionapiGetAllStart,
    allConversionapiGetAllSuccess,
    allConversionapiGetAllFail,
} = conversionapi?.actions;
export default conversionapi.reducer;
