import { createSlice } from "@reduxjs/toolkit";

const offerProducts = createSlice({
  name: "offerProducts",
  initialState: {
    offerProducts: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    offerProductsGetAllStart: (state) => {
      state.isFetching = true;
    },
    offerProductsGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.offerProducts = action.payload;
    },
    offerProductsGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  offerProductsGetAllStart,
  offerProductsGetAllSuccess,
  offerProductsGetAllFail,
} = offerProducts?.actions;
export default offerProducts.reducer;
