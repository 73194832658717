import { createSlice } from '@reduxjs/toolkit';

const couponData = createSlice({
  name: 'couponData',
  initialState: {
    couponData: null, // Tracks the number of items in the local cart
  },
  reducers: {
    updateCouponData: (state, action) => {
      state.couponData = action.payload; // Update the cart length
    },
  },
});

export const { updateCouponData } = couponData.actions;
export default couponData.reducer;