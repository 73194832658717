import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Form, Button, message } from "antd";
import image1 from "../../Assets/Images/GirlsHub Template/f-1.png";
import image2 from "../../Assets/Images/GirlsHub Template/f-2.png";
import image3 from "../../Assets/Images/GirlsHub Template/f-3.png";
import image4 from "../../Assets/Images/GirlsHub Template/f-4.png";
import image5 from "../../Assets/Images/GirlsHub Template/f-5.png";
import image6 from "../../Assets/Images/GirlsHub Template/f-6.png";
import image7 from "../../Assets/Images/GirlsHub Template/f-7.png";
import image8 from "../../Assets/Images/GirlsHub Template/f-8.png";
import image9 from "../../Assets/Images/GirlsHub Template/f-9.png";
import image10 from "../../Assets/Images/GirlsHub Template/f-10.png";
import image11 from "../../Assets/Images/GirlsHub Template/f-11.png";
import image12 from "../../Assets/Images/GirlsHub Template/f-12.png";
import image13 from "../../Assets/Images/GirlsHub Template/f-13.png";
import image14 from "../../Assets/Images/GirlsHub Template/f-14.png";
import { Link } from "react-router-dom";
import { base_url } from "../../config";
import API from "../../ApiService/ApiService";
const Footer10 = ({ company, social, home_custom, content_all }) => {
  // console.log("company=====>",company)
  // console.log("home_custom=====>",home_custom)
  // console.log("content_all==>Footer10", content_all)
  const [categorys, setCategory] = useState([]);
  const [finalOne, setFinalOne] = useState([]);

  let token = localStorage.getItem("token") || "";
  let login = localStorage.getItem("login") || false;
  let userData = localStorage.getItem("user") || null;

  useEffect(() => {    
    getAllCategory();
  }, []);

  const getAllCategory = async(category) => {
    await api.getcategory().then((res) => {
      if(res?.data?.success === true){
        const data = res?.data?.data?.filter((item) => item?.status == true);
        setCategory(data);
        categoryTree(data);
      }
    })
  }

  const categoryTree = (category) => {
    let filter = category.filter((e) => {
      return e.category_id == null;
    });
    // console.log("filter", filter);
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // console.log("final", final);
    setFinalOne(final);
    return final;
  };
  //subcategory tree
  const categoryTree2 = (id, category) => {
    let filter = category.filter((e) => {
      return e.category_id == id;
    });
    let final = filter.map((e) => {
      return {
        value: e?._id,
        label: e?.name,
        children:
          categoryTree2(e._id, category).length > 0
            ? categoryTree2(e._id, category)
            : "",
      };
    });
    // setFinalOne(final);
    return final;
  };

  const Icons = [
    {
      image: image1,
    },
    {
      image: image2,
    },
    {
      image: image3,
    },
    {
      image: image4,
    },
    {
      image: image5,
    },
    {
      image: image6,
    },
    {
      image: image7,
    },
    {
      image: image8,
    },
    {
      image: image9,
    },
    {
      image: image10,
    },
    {
      image: image11,
    },
    {
      image: image12,
    },
    {
      image: image13,
    },
    {
      image: image14,
    },
  ];
  const [form] = Form.useForm();
  const api = new API();

  const onFinish = (values) => {
    api.createCommon("subscriber", values).then((res) => {
      if (res?.status == 201) {
        form.resetFields();
        message.success(res?.data?.message);
      } else {
        message.error("Something went wrong");
      }
    });
  };

  return (
    <Section>
      <div className="container">
        <div className="left_footer">
          <div className="nav_links_container">
            <h3>Help</h3>
            <ul className="nav_links">
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/my-profile"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  My Profile
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/delivery-address"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Delivery Address
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/change-password"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Change Password
                </Link>
              </li>
              <li hidden={!login && !token && !userData}>
                <Link
                  to="/my-order"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Track Your Order
                </Link>
              </li>
              <li>
                <Link
                  to="/terms-and-condition"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Terms & Condition
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Contact Us
                </Link>
              </li>

              {/* <li>Shipping Policy</li> */}
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>About Us</h3>
            <ul className="nav_links">
              <li>
                <Link
                  to="/about"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Our Story
                </Link>
              </li>
              {/* <li>FAQ’s</li> */}
              <li>
                <Link
                  to="/privacy-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/delivery-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Delivery Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/cancellation-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Cancellation Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/return-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Return Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/refund-policy"
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Refund Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav_links_container">
            <h3>Category</h3>
            <ul className="nav_links">
              {
                finalOne?.map((item, index) => (
                  // console.log("item", item),
                  <li key={index}>
                    <Link
                      to={`/products/${item?.label.replace(/\s+/g, '-')}`}
                      style={{ textDecoration: "none", color: "#000" }}
                    >
                      {item?.label}
                    </Link>
                  </li>
                ))
                // (<li><Link to="/products" style={{ textDecoration: 'none' , color: '#000'}}>Maternity Leggins</Link></li>)
              }
            </ul>
          </div>
        </div>
        <div className="right_footer">
          <p className="title">subscribe for latest updates</p>
          <Form
            className="input_container"
            form={form}
            onFinish={onFinish}
            style={{ paddingBottom: "0px" }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  pattern: new RegExp(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  required: true,
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                placeholder="Enter your email"
                className="input"
                style={{
                  width: "145%",
                  paddingLeft: "10px",
                  borderRadius: "2px 0 0 2px",
                  border: "1px solid #000",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button className="button" type="primary" htmlType="submit">
                SUBSCRIBE{" "}
              </Button>
            </Form.Item>
          </Form>
          <p className="subscription">
            By clicking the SUBSCRIBE button, you are agreeing to our{" "}
            <u>
              <Link
                to="/privacy-policy"
                style={{ textDecoration: "none", color: "#000" }}
              >
                Privacy & Cookie Policy
              </Link>
            </u>
          </p>
          {/* <p className="title">SIGN UP FOR GIRLSHUB Closet STYLE NEWS</p>
          <div className="input_container">
            <input placeholder="your email" />
            <button>SUBSCRIBE </button>
          </div>
          <u></u>
          <p className="subscription">
            By clicking the SUBSCRIBE button, you are agreeing to our{" "}
            <u><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy & Cookie Policy</Link></u>
          </p> */}
        </div>
      </div>
      {/* <div className="footer_bottom_Main">
        <div className="footer_bottom_left">
          <p className="rights">
            ©2010-2022 Laura’s Closet All Rights Reserved
          </p>
          <ul className="Bottom_List">
            <li><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy Center</Link></li>
            <li><Link to="/privacy-policy" style={{ textDecoration: 'none' , color: '#000'}}>Privacy & Cookie Policy</Link></li>
            <li>Manage Cookies</li>
            <li><Link to="/terms-and-condition" style={{ textDecoration: 'none' , color: '#000'}}>Terms & Conditions</Link></li>
            <li>Copyright Notice</li>
            <li>Imprint</li>
          </ul>
        </div> 
         <div className="footer_bottom_right">
          <p className="Title" style={{ textAlign: "center" }}>We accept</p>
          <div className="icons_Main">
            {Icons.map((icon, index) => (
              <div className="icon" key={index}>
                <img src={icon.image} alt="icons" />
              </div>
            ))}
          </div>
        </div>
      </div> */}
      <div className="Footer_Bottom_bar">
        {/* <Link to="https://www.ecdigi.com/" target="_blank" style={{ textDecoration: 'none' , color: '#000'}}> */}
        <div className="Left_Bar">
          Copyright & Designed by{" "}
          <Link
            to="https://www.ecdigi.com/"
            target="_blank"
            style={{ textDecoration: "none", color: "white" }}
          >
            ecDigi
            {/* {content_all?.designed_by} */}
          </Link>
        </div>
        {/* </Link> */}
        {/* <div className="Right_Bar">Designed by Blazon</div> */}
      </div>
    </Section>
  );
};

export default Footer10;

const Section = styled.section`
  background-color: #fbfbfb;
  padding-top: 100px;
  /* padding-bottom: 50px; */

  .container {
    display: flex;
    gap: 40px;
    margin-bottom: 40px;
    .left_footer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 60%;

      .nav_links_container {
        h3 {
          color: #000;
          /* font-family: Euclid Circular A; */
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 110%; /* 26.4px */
        }
        ul {
          margin: 0;
          padding: 0;

          li {
            font-size: 14px;
            margin: 7px 0;
          }
        }
      }
    }
    .right_footer {
      width: 40%;

      .input_container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 15px;

        input {
          width: 60%;
          padding-left: 10px;
        }

        button {
          background: var(--grey-grey-800, #1f2937);
          display: flex;
          padding: 7px 30px;
          align-items: flex-start;
          gap: 10px;
          color: white;
          border: none;
        }
      }

      .title {
        color: #000;
        /* font-family: Euclid Circular A; */
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .subscription {
        color: #000;
        /* font-family: Euclid Circular A; */
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.7px;
      }
    }
  }
  .bottom_footer {
    padding-top: 25px;

    .rights {
    }
  }

  .footer_bottom_Main {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 100px;
  }

  .footer_bottom_Main .footer_bottom_right {
    width: 30%;
  }

  .footer_bottom_Main .footer_bottom_right .icons_Main {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 10px;
  }

  .footer_bottom_Main .footer_bottom_left {
    width: 50%;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  .footer_bottom_Main .footer_bottom_left .Bottom_List li {
    color: #000;

    font-family: Euclid Circular A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    text-decoration-line: underline;
  }

  .footer_bottom_Main .footer_bottom_left .rights {
    color: #000;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.7px;
    padding-left: 20px;
  }

  .Footer_Bottom_bar {
    width: 100%;
    height: 44.441px;
    flex-shrink: 0;
    background: #f65c7f;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    padding: 0 100px;
    margin-top: 40px;
  }

  .Footer_Bottom_bar .Left_Bar,
  .Footer_Bottom_bar .Right_Bar {
    color: #fff;
    font-family: Euclid Circular A;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  @media screen and (min-width: 1441px) and (max-width: 2560px) {
    .container {
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .right_footer {
      width: 50% !important;
      margin: 0 auto !important;
    }
    
  }

  @media screen and (min-width: 1200px) and (max-width: 1440px) {
    .container {
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .right_footer {
      width: 50% !important;
      margin: 0 auto !important;
    }
    
  }

  @media screen and (min-width: 1025px) and (max-width: 1199px) {
    .container {
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .right_footer {
      width: 80% !important;
      margin: 0 auto !important;
    }
    
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    .container {
      display: block;
      width: 100%;
      /* border: 1px solid red; */
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .right_footer {
      width: 40% !important;
      /* border: 1px solid red; */
      margin: 0 auto !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
    }
    .right_footer {
      width: 60% !important;
      margin: 0 auto !important;
    }
  }

  @media screen and (min-width: 540px) and (max-width: 767px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(3, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 70% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
  }

  @media screen and (min-width: 430px) and (max-width: 539px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(2, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
  }

  @media screen and (min-width: 425px) and (max-width: 429px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;
      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
  }

  @media screen and (min-width: 414px) and (max-width: 424px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;
      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
  }

  @media screen and (min-width: 412px) and (max-width: 413px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
  }

  @media screen and (min-width: 390px) and (max-width: 411px) {
    .container {
      display: block;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 389px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 360px) and (max-width: 374px) {
    .container {
      display: block;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;

      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
    .Footer_Bottom_bar {
      padding: 0px 30px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 359px) {
    .container {
      display: block;
      width: 100%;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      /* border: 1px solid red; */
      /* text-align: center; */
      margin-left: 20px;
      gap: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;
      margin-top: 20px !important;
      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
    .Footer_Bottom_bar {
      /* border: 1px solid red; */
      padding: 0 30px;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 319px) {
    .container {
      display: block;
    }
    .left_footer {
      width: 90% !important;
      grid-template-columns: repeat(1, 1fr) !important;
      margin-left: 20px;
      gap: 20px;
    }
    .right_footer {
      width: 90% !important;
      margin: 0 auto !important;
      margin-top: 20px !important;
      .input_container {
        flex-direction: column;

        .input {
          width: 90% !important;          
        }
      }
    }
    .Footer_Bottom_bar {
      /* border: 1px solid red; */
      padding: 0 30px;
    }    
  }
`;
