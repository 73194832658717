import React, { useState, useEffect, useRef } from "react";

const LazyImage = ({ src, alt, placeholder, ...props }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const imageRef = useRef();
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );
  
      if (imageRef.current) {
        observer.observe(imageRef.current);
      }
  
      return () => {
        if (observer && imageRef.current) {
          observer.unobserve(imageRef.current);
        }
      };
    }, []);
  
    return (
      <div style={{ position: "relative" }}>
        {!isLoaded && placeholder && (
          <img
            src={placeholder}
            alt="placeholder"
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
        <img
          ref={imageRef}
          src={isVisible ? src : ""}
          alt={alt}
          onLoad={() => setIsLoaded(true)}
          style={isLoaded ? {} : { visibility: "hidden" }}
          loading="lazy"
          {...props}
        />
      </div>
    );
  };

export default LazyImage;
