import { createSlice } from "@reduxjs/toolkit";

const topSellingProducts = createSlice({
  name: "topSellingProducts",
  initialState: {
    topSellingProducts: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    topSellingProductsGetAllStart: (state) => {
      state.isFetching = true;
    },
    topSellingProductsGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.topSellingProducts = action.payload;
    },
    topSellingProductsGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  topSellingProductsGetAllStart,
  topSellingProductsGetAllSuccess,
  topSellingProductsGetAllFail,
} = topSellingProducts?.actions;
export default topSellingProducts.reducer;
