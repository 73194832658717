import { createSlice } from "@reduxjs/toolkit";

const allproduct = createSlice({
  name: "allproduct",
  initialState: {
    allproduct: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    allProductGetAllStart: (state) => {
      state.isFetching = true;
    },
    allProductGetAllSuccess: (state, action) => {
      state.isFetching = false;
      state.allproduct = action.payload;
    },
    allProductGetAllFail: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
    allProductGetAllStart,
    allProductGetAllSuccess,
    allProductGetAllFail,
} = allproduct?.actions;
export default allproduct.reducer;
