import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { company_code, base_url, api_website, api_admin, web_base_url, web_base_url1 } from "../config";

const UseCanonical = () => {
  const location = useLocation();

  // useEffect(() => {
  //   const link = document.querySelector("link[rel='canonical']");
  //   const canonicalUrl = `${web_base_url1}${location.pathname}`;
  //   console.log("canonicalUrl", canonicalUrl);

  //   if (link) {
  //     link.href = canonicalUrl;
  //   } else {
  //     const newLink = document.createElement('link');
  //     newLink.rel = 'canonical';
  //     newLink.href = canonicalUrl;
  //     document.head.appendChild(newLink);
  //   }
  // }, [location]);

  useEffect(() => {
    const generateCanonicalUrl = () => {
      // Generate a unique and clean canonical URL
      const cleanPath = location.pathname.replace(/\/$/, ""); // Remove trailing slash
      const canonicalUrl = `${web_base_url1}${cleanPath}`;
      // return canonicalUrl;
      return canonicalUrl.split("?")[0]; // Strip query parameters
    };

    const setCanonicalTag = (url) => {
      const existingLink = document.querySelector("link[rel='canonical']");
      if (existingLink) {
        existingLink.href = url;
      } else {
        const newLink = document.createElement("link");
        newLink.rel = "canonical";
        newLink.href = url;
        document.head.appendChild(newLink);
      }
    };

    const canonicalUrl = generateCanonicalUrl();
    // console.log("Generated Canonical URL:", canonicalUrl);

    setCanonicalTag(canonicalUrl);
  }, [location]);
};

export default UseCanonical;