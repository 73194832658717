import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { base_url } from "../../config";

import {
  Badge,
  Menu,
  Button,
  Dropdown,
  Drawer,
  Col,
  Tooltip,
  Space,
  Tabs,
  Avatar,
  Cascader,
} from "antd";
import {
  ShoppingCartOutlined,
  ShoppingOutlined,
  LogoutOutlined,
  DownOutlined,
  ProfileOutlined,
  SearchOutlined,
  MailFilled,
  PhoneFilled,
  CaretDownOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import { styles } from "../../ApiService/Data";

import logo from "../../Assets/Images/logo.png";

import SearchBoxComponent from "../../Ecommerce/SearchBox";

import API from "../../ApiService/ApiService";

import { useSelector, useDispatch } from "react-redux";

import { logoutUser } from "../../Redux/slices/user";
import { emptyRemoveAddress } from "../../Redux/slices/removeAddress";
import { emptyUpdateAddress } from "../../Redux/slices/updateAddress";
import { emptyUserAddress } from "../../Redux/slices/userAddress";
import { FaPhoneSquareAlt } from "react-icons/fa";

import { getCartList } from "../../Redux/Store";

// import MobileMenu from "../";
const user = JSON.parse(localStorage.getItem("persist:root"))?.company;
const colorCustom = user && JSON.parse(user).setColor;
const { SubMenu } = Menu;

const Header3 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.user);
  const categoryList = useSelector((state) => state?.CetgoryMenu?.CetgoryMenu);
  // console.log("category list",categoryList)
  const cartList = useSelector((state) => state?.cart?.products);
  const [search, setSearch] = useState(true);
  const [category, setCategory] = useState([]);
  const [visible, setVisible] = useState(false);

  const socialMediaLink = useSelector(
    (state) => state?.company?.socialMediaLinks
  );
  const api = new API();
  const company = useSelector((state) => {
    return state?.company?.value;
  });

  let token = localStorage.getItem("token");
  let login = localStorage.getItem("login");
  let userData = localStorage.getItem("user");

  const toogleSearch = () => {
    setSearch(!search);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const renderMenuItems = (categories) => {
    return categories.map((category) => {
      if (category.children) {
        return (
          <Menu.SubMenu key={category.key} title={category.label}>
            {renderMenuItems(category.children)}
          </Menu.SubMenu>
        );
      }
      return (
        <Menu.Item key={category?.key}>
          <Link to={`/products?catId=${category?.key}`}>{category?.label}</Link>
        </Menu.Item>
      );
    });
  };

  const logout = () => {
    //data of the user will be deleted from local storage after logout and also set null value to userSlice
    dispatch(logoutUser(null));
    dispatch(emptyRemoveAddress(null));
    dispatch(emptyUpdateAddress(null));
    dispatch(emptyUserAddress(null));
    localStorage.setItem("user", "");
    localStorage.setItem("token", "");
    localStorage.setItem("login", false);
    navigate("/");
    window.location.reload();
  };

  const menu = (
    <Menu onClick={onClose} mode="horizontal">
      <Menu.Item key="1_cart">
        <Link to="/cart">
          <Badge count={cartList?.length}>
            <Button type="text">
              <ShoppingCartOutlined />
              Cart
            </Button>
          </Badge>
        </Link>
      </Menu.Item>
      <Menu.Item key="1_profile">
        <Link to="/my-profile">
          <ProfileOutlined /> Profile
        </Link>
      </Menu.Item>
      <Menu.Item key="1_order">
        <Link to="/my-order">
          <ShoppingOutlined /> My Order
        </Link>
      </Menu.Item>
      <Menu.Item key="1_signout">
        <a onClick={logout}>
          <LogoutOutlined /> Sign out
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <HeaderSection>
        <header className="Temp3_Header">
          <div className="Temp3_Header_Top">
            <div className="wrapper">
              <div className="Temp3_Header_Top_Align">
                <div className="Temp3_Header_Top_Left">
                  <Link to="/">
                    <img
                      className="header_logo_t3"
                      src={company?.logo ? base_url + company?.logo : logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="Temp3_Header_Top_Center">
                  {search && <SearchBoxComponent style={{ width: "100%" }} />}
                </div>

                <Col className="mobile-view">
                  {/************  mobile navication ******************/}
                  <div className="mobile-navigation">
                    <div className="mobile-menu">{/* <MobileMenu /> */}</div>
                  </div>
                </Col>

                <div className="Temp3_Header_Top_Right">
                  <div className="desktop-view tab-none">
                    {login && token && userData ? (
                      <div>{menu}</div>
                    ) : (
                      <Link to="/login">
                        <Button type="text">
                          <LoginOutlined /> Login
                        </Button>
                      </Link>
                    )}
                  </div>
                  {/************  mobile navication ******************/}
                  <div className="mobile-navigation">
                    <div className="mobile-menu">{/* <MobileMenu /> */}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="Temp3_Header_Bottom">
            <div className="wrapper">
              <div className="Temp3_Header_Bottom_Align">
                <div className="Temp3_Header_Bottom_Category_Menu">
                  <div className="nav-category-list">
                    <Menu mode="horizontal">
                      <Menu.SubMenu
                        title="All Categories"
                        className="t3-submenu"
                      >
                        {/* {category?.map((e, i) => {
                          return (
                            <Menu.Item key={`menuPdttemp3m_${i}`}>
                              <Link
                                to={`/${e?.category_name
                                  .toLowerCase()
                                  .replace(/ /g, "-")
                                  .replace(/[^\w-]+/g, "")}`}
                              >
                                {e?.category_name}
                              </Link>
                            </Menu.Item>
                          );
                        })} */}
                        {renderMenuItems(categoryList ? categoryList : [])}
                      </Menu.SubMenu>
                    </Menu>
                  </div>
                  <div className="down_Arrow">
                    <CaretDownOutlined />
                  </div>
                </div>
                <div className="Temp3_Header_Bottom_Primary_Menu">
                  <div className="nav-menu-list">
                    <Menu mode="horizontal">
                      <Menu.Item key="home">
                        <Link to="/">Home</Link>
                      </Menu.Item>
                      <Menu.Item key="about">
                        <Link to="/about">About Us</Link>
                      </Menu.Item>
                      {/* <Menu.Item key="shop">
                      <Link to="#">Shop</Link>
                    </Menu.Item> */}
                      <Menu.Item key="contact">
                        <Link to="/contact">Contact Us</Link>
                      </Menu.Item>
                      <Menu.Item key="blog">
                        <Link to="https://ecdigi.com/blog.php" target="_blank">Blog</Link>
                      </Menu.Item>
                      <Menu.Item key="products">
                        <Link to="/Products">Products</Link>
                      </Menu.Item>
                    </Menu>
                  </div>
                </div>
                <div className="Temp3_Header_Bottom_CalltoAction">
                  <div className="header-action">
                    <div className="phone-item-action">
                      <div className="phone-item-icon">
                        {/* <CustomerServiceOutlined
                          style={{
                            fontSize: "28px",
                            color: "#ee8f0a",
                            paddingRight: "15px",
                          }}
                        /> */}
                        <FaPhoneSquareAlt className="phone-icon" />
                      </div>
                      <div className="phone-item-content">
                        <p>Call us</p>
                        {company?.mobile_no && (
                          <p>
                            <a href={"tel:+91" + company?.mobile_no}>
                              {company?.mobile_no}
                            </a>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </HeaderSection>
    </>
  );
};

export default Header3;

const HeaderSection = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #ffff;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  .Temp3_Header {
    display: inline-block;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
  }

  .Temp3_Header .Temp3_Header_Top {
    padding: 15px 0;
    width: 100%;
    display: inline-block;
  }

  .Temp3_Header .Temp3_Header_Top .Temp3_Header_Top_Align {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 10px;
    flex-wrap: wrap;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Left {
    width: fit-content;
    display: inline-block;
    position: relative;
    margin: auto auto auto 0;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Left
    img {
    margin: auto;
    height: 45px;
  }

  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 35%;
    margin: auto;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Center
    span.ant-input-affix-wrapper {
    border-radius: 30px 0 0 30px !important;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Center
    input {
    padding: 8px 15px;
    border-radius: 30px 0 0 30px !important;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Center
    button {
    height: 48px;
    width: 55px;
    border-radius: 0px 30px 30px 0 !important;
    background: ${styles?.colorapi};
    color: ${styles?.white} !important;
    border: 1px solid $bgcolor;
  }

  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right {
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto 0 auto auto;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    button {
    padding: 5px 0;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    li {
    padding: 0 !important;
    margin: 0 25px 0 0;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    li::after,
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    li::before {
    content: inherit;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    li:nth-child(4) {
    margin: 0;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    .desktop-view {
    position: relative;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    button {
    align-items: center;
    display: flex;
    border: 1px solid black;
    padding: 0 10px;
  }
  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    .ant-menu-horizontal
    > .ant-menu-item
    a {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .Temp3_Header
    .Temp3_Header_Top
    .Temp3_Header_Top_Align
    .Temp3_Header_Top_Right
    .ant-menu-horizontal {
    line-height: 32px;
  }

  .Temp3_Header_Bottom {
    display: block;
    position: relative;
    width: 100%;
    background: ${styles?.light};
    float: left;
  }
  .Temp3_Header_Bottom .Temp3_Header_Bottom_Align {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 47px;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Category_Menu {
    width: 204px;
    position: relative;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Category_Menu
    .down_Arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    color: #fdf3e5;
  }

  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Category_Menu
    ul
    li {
    opacity: 1;
    order: 0;
    width: 200px;
    background: ${styles?.colorapi};
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    padding: 0 !important;
    text-align: left;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Category_Menu
    ul
    li
    span.ant-menu-title-content {
    color: #fff;
    padding: 0 0 0 20px;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Primary_Menu {
    width: 60%;
    margin: 0 0 0 10px;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_Primary_Menu
    ul
    li {
    padding: 0 10px;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_CalltoAction {
    width: fit-content;
    margin: 0 0 0 auto;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_CalltoAction
    ul
    li
    a:hover {
    color: #ee8f0a !important;
    font-weight: 600;
  }

  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_CalltoAction
    .phone-item-action {
    display: flex;
    align-items: center;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_CalltoAction
    .phone-item-action
    p {
    margin: 0%;
    color: ${styles?.color};
    font-family: ${styles?.bold};
    line-height: 1.3;
  }
  .Temp3_Header_Bottom
    .Temp3_Header_Bottom_Align
    .Temp3_Header_Bottom_CalltoAction
    .phone-item-action
    p
    * {
    color: ${styles?.color};
    font-family: ${styles?.bold};
  }
  .phone-item-icon .anticon.anticon-customer-service {
    color: ${styles?.colorapi} !important;
  }

.header_top_right .shop_cart .accounts_section{
background-color: red;
  }

  @media screen and (max-width: 992px) {
    .Temp3_Header
      .Temp3_Header_Top
      .Temp3_Header_Top_Align
      .Temp3_Header_Top_Right {
      display: none;
    }

    .Temp3_Header_Bottom {
      display: none;
    }

    .Temp3_Header .Temp3_Header_Top .Temp3_Header_Top_Align .mobile-menu,
    .Temp3_Header .Temp3_Header_Top .Temp3_Header_Top_Align .mobile-view {
      display: block;
      margin: auto 0 auto auto;
    }
    .Temp3_Header .Temp3_Header_Top .Temp3_Header_Top_Align .ant-col {
      padding: 0;
    }
    .Temp3_Header
      .Temp3_Header_Top
      .Temp3_Header_Top_Align
      .mobile-view
      button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 768px) {
    .Temp3_Header .Temp3_Header_Top .Temp3_Header_Top_Align {
      padding: 0 0 60px;
    }
    .Temp3_Header
      .Temp3_Header_Top
      .Temp3_Header_Top_Align
      .Temp3_Header_Top_Center {
      position: absolute;
      bottom: 15px;
      width: auto;
      left: 10px;
      right: 10px;
    }
  }
  .phone-icon {
    margin-right: 10px;
    font-size: 25px;
    color: #ff0000;
  }
`;
