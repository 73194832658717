import { userRequest, publicRequest, userRequest1 } from "./RequestMethod";
import menuFunction from "../Utils/menu";
import axios from "axios";
import { company_code } from '../config'

const getUser = localStorage.getItem("user");

let user = "";

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

class API {
  constructor() {
    this.rootUrl = process.env.REACT_APP_API_WEBSITE;
    this.rootUrl1 = process.env.REACT_APP_BASE;
    this.rootUrl2 = process.env.REACT_APP_API_ADMIN;
  }

  // crud service
  getAll = async (dispatch, action, url, params, data) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.get(url, { params: params, data: data });
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };
  getAll1 = async (url, params) => {
    try {
      const res = await userRequest1.get(url, { params: params });
      return res;
    } catch (error) {
      return error;
    }
  };
  getAllTax = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.get(url, { params: params });
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };
  getAllCategory = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.get(url, { params: params });
      // console.log("res.data", res?.data?.data);

      let data = await menuFunction(res?.data?.data);

      dispatch(action[1](data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };

  getSingle = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.get(`${url}/${params}`);
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };

  create = async (dispatch, action, url, data) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.post(url, data);
      dispatch(action[1](res?.data));
      // console.log("res------->", res);
      return res;
    } catch (error) {
      dispatch(action[2]);
      // console.log("res------->", error);
      return error;
    }
  };

  create1 = async (dispatch, action, url, data, callback) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.post(url, data);
      dispatch(action[1](res?.data));
      if (callback) callback(null, res); // Call the callback with the response
      return res;
    } catch (error) {
      dispatch(action[2]);
      if (callback) callback(error, null); // Call the callback with the error
      return error;
    }
  };

  update = async (dispatch, action, url, params, data) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.put(`${url}/${params}`, data);
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };

  remove = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await userRequest.delete(`${url}/${params}`);
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };

  getcategory = async (data) => {
    try {
      const res = await userRequest.get(`category`);
      return res;
    } catch (error) {
      return error;
    }
  }

  // common crud service
  createCommon = async (url, data) => {
    try {
      const res = await userRequest.post(url, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getCommonAll1 = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await publicRequest.get(url, { params: params });
      // console.log("received data =>> ",res?.data);
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };
  getCommonAll2 = async (dispatch, action, url, params) => {
    dispatch(action[0]);
    try {
      const res = await publicRequest.get(url, { params: params });
      // console.log("received data =>> ",res?.data);
      dispatch(action[1](res?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };
  getCommonAll = async (url, params) => {
    try {
      const res = await publicRequest.get(url, { params: params });
      return res;
    } catch (error) {
      return error;
    }
  };

  getCommonAllforUser = async (url, params) => {
    try {
      const res = await userRequest.get(url, { params: params });
      return res;
    } catch (error) {
      return error;
    }
  };

  getData = async (dispatch, action, url, data) => {
    dispatch(action[0]);
    try {
      const res = await axios.post(url, data);
      dispatch(action[1](res?.data?.data));
      return res;
    } catch (error) {
      dispatch(action[2]);
      return error;
    }
  };

  getSingleProduct = async (data) => {
    try {
      const res = await userRequest.get(`products/${data}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getProductDiscountDetails = async (data) => {
    try {
      const res = await userRequest.get(`products/discounts/${data}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  //Common APi

  createCommonRatingApi = async (data) => {
    try {
      const res = await userRequest.post("common", data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getCommonApiRating = async () => {
    try {
      const res = await userRequest.get(`common?mode=rating`);
      return res;
    } catch (error) {
      return error;
    }
  };

  addCart = async (data) => {
    try {
      const res = await userRequest.post(`cart`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  addWiseList = async (data) => {
    try {
      const res = await userRequest.post(`wishlist`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getcart = async (data) => {
    try {
      const res = await userRequest.get(`cart`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getcoupon = async (data) => {
    try {
      const res = await userRequest.get(`coupon?company_code=${company_code}&user_id=${user?._id}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getWishList = async (data) => {
    try {
      const res = await userRequest.get(`wishlist`, data);
      return res;
    } catch (error) {
      return error;
    }
  };
  deleteWishList = async (id) => {
    try {
      const res = await userRequest.delete(`wishlist/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  getcheckout = async (data) => {
    try {
      const res = await userRequest.get(`checkout`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getmyorder = async (data) => {
    try {
      const res = await userRequest.get(`myorder`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  deletecart = async (productId) => {
    try {
      const res = await userRequest.delete(`cart/${productId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  updatecart = async (id, type) => {
    try {
      const res = await userRequest.put(`cart/${id}`, { type: type });
      return res;
    } catch (error) {
      return error;
    }
  };

  checkout = async (data) => {
    try {
      const res = await userRequest.post(`checkout`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getPriceRange = async () => {
    try {
      const res = await userRequest.get("priceRange");
      return res;
    } catch (error) {
      return error
    }
  }

  placeorder = async (data) => {
    try {
      const res = await userRequest.post(`placeorder`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getSignleOrder = async (id) => {
    try {
      const res = await userRequest.get(`myorder/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  };
  getSingleAuthtoken = async () => {
    try {
      const res = await userRequest.get(`authtokenforwebsite`);
      return res;
    } catch (error) {
      return error;
    }
  }
  getMyProfile = async (userId) => {
    try {
      const res = await userRequest.get(`profile/${userId}`);
      return res;
    } catch (error) {
      return error;
    }
  };

  UpdateProfile = async (userId, data) => {
    try {
      const res = await userRequest.put(`profile/${userId}`, data);
      return res;
    } catch (error) {
      return error;
    }
  };

  getCountry = async () => {
    try {
      const res = await userRequest.get("country");
      return res;
    } catch (err) {
      return err;
    }
  };

  getState = async (countryId) => {
    try {
      const res = await userRequest.get(`state?country=${countryId}`);
      return res;
    } catch (err) {
      return err;
    }
  };

  getdistrict = async (stateId) => {
    try {
      const res = await userRequest.get(`district?state=${stateId}`);
      return res;
    } catch (err) {
      return err;
    }
  };

  getArea = async (districtId) => {
    try {
      const res = await userRequest.get(`area?district=${districtId}`);
      return res;
    } catch (err) {
      return err;
    }
  };

  addenquiry = async (data) => {
    try {
      const res = await userRequest.post(`enquiryform`, data);
      return res;
    } catch (error) {
      return error;
    }
  }

  getblogs = async () => {
    try {
      const res = await userRequest.get(`blogs`);
      return res;
    } catch (err) {
      return err;
    }

  }

  getSingleblogs = async (blogId) => {
    try {
      const res = await userRequest.get(`blogs/${blogId}`);
      return res;
    } catch (err) {
      return err;
    }
  }

  getdeliverycheck = async () => {
    try {
      const res = await userRequest.get(`deliverydetails`);
      return res;
    } catch (err) {
      return err;
    }
  }


  getproductspec = async (params, product_id) => {
    const queryParams = params.spec_ids
      .map((spec_id, index) => {
        return `spec_id=${spec_id}&detail_id=${params.detail_ids[index]}`;
      })
      .join("&");
    // console.log("queryParams--->", params);
    try {
      const res = await userRequest.get(
        `products/${product_id}?${queryParams}`
      );
      return res;
    } catch (err) {
      throw err;
    }
  };


  getBanner = async () => {
    try {
      const res = await userRequest.get(`templatecustom`);
      return res;
    } catch (error) {
      return error;
    }
  }
  getInvoice = async (id) => {
    try {
      const res = await userRequest.get(`invoice/${id}`);
      return res;
    } catch (error) {
      return error;
    }
  }
}

export default API;
